<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="환산표"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :usePaging="false"
        :columnSetting="false"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-plan-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '항목',
            align: 'left',
            sortable: false
          },
          {
            name: 'maxRealScoring',
            field: 'maxRealScoring',
            label: '최고실배점',
            align: 'center',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'changeCalculation',
            field: 'changeCalculation',
            label: '환산계수',
            align: 'center',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'maxChangeScoring',
            field: 'maxChangeScoring',
            label: '최고환산점수',
            align: 'center',
            style: 'width:200px',
            sortable: false
          },
        ],
        data: [],
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sop.pca.item.revs.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {sysRevision: this.popupParam.sysRevision};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
